import type { IRequestOptions, ApiRequestOptions as TApiRequestOptions } from 'o365.pwa.declaration.sw.apiRequestOptions.ApiRequestOptions.d.ts';
import type { TruncateIndexDBObjectStoreMode } from "o365.pwa.types.ts";
import type { Request } from 'o365.pwa.declaration.sw.ServiceWorkerGlobalScope.d.ts';
import type { IOfflineRecordSourceOptions, IRecordSourceFieldType } from 'o365.pwa.declaration.sw.apiRequestOptions.RecordSourceOptions.d.ts';

declare module 'o365.pwa.declaration.sw.apiRequestOptions.ApiPwaOfflineSyncRequestOptions.d.ts' {
    export interface IApiPwaOfflineSyncOptions extends IRequestOptions {
        requestGuid: string;

        appId: string;
        dataObjectId: string;

        appIdOverride?: string;
        databaseIdOverride?: string;
        objectStoreIdOverride?: string;
        
        personID?: number;
        deviceRef?: string;

        isFileTable: boolean;

        truncateOptions: IApiPwaOfflineSyncTruncateOptions;
        generateOfflineDataOptions: IApiPwaOfflineSyncGenerateOfflineDataOptions;
        rowCountOptions: IApiPwaOfflineSyncRowCountOptions;
        retrieveOptions: IApiPwaOfflineSyncRetrieveOptions;
    }

    export interface IApiPwaOfflineSyncTruncateOptions {
        truncateMode: TruncateIndexDBObjectStoreMode;
    }

    export interface IApiPwaOfflineSyncGenerateOfflineDataOptions {
        shouldGenerateOfflineData: boolean;
        dataObjectOptions: IOfflineRecordSourceOptions;
        originalViewName: string;
        viewName: string;
        fields: Array<IRecordSourceFieldType>;
        generateOfflineDataProcName: string;
        generateOfflineDataViewNameOverride: string;
        generateOfflineDataProcedureNameOverride: string;
    }

    export interface IApiPwaOfflineSyncRowCountOptions {
        timeout?: number;
        failOnNoRecords?: boolean;
        dataObjectOptions: IOfflineRecordSourceOptions;
    }

    export interface IApiPwaOfflineSyncRetrieveOptions {
        dataObjectOptions: IOfflineRecordSourceOptions;
    }

    export class ApiPwaOfflineSyncOptions implements IApiPwaOfflineSyncOptions {
        readonly requestGuid: string;
        readonly appId: string;
        readonly dataObjectId: string;
        readonly appIdOverride?: string;
        readonly databaseIdOverride?: string;
        readonly objectStoreIdOverride?: string;
        readonly personID?: number;
        readonly deviceRef?: string;
        readonly isFileTable: boolean;
        readonly truncateOptions: IApiPwaOfflineSyncTruncateOptions;
        readonly generateOfflineDataOptions: IApiPwaOfflineSyncGenerateOfflineDataOptions;
        readonly rowCountOptions: IApiPwaOfflineSyncRowCountOptions;
        readonly retrieveOptions: IApiPwaOfflineSyncRetrieveOptions;

        constructor(options: IApiPwaOfflineSyncOptions);

        static fromRequest(request: Request): Promise<TApiRequestOptions<ApiPwaOfflineSyncOptions>>;
    }
}
